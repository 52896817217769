<template>
    <div class="modal-send-like">
        <div class="like-box w-100">
            <i class="material-icons i-right f-30" @click="onClickClose()">close</i>

            <div class="user-nickname f-bold f-24 m-l-25 m-r-25">
                {{ options.target.profile.nickname }}
            </div>
            <div
                v-if="options.type === 'personalStory'"
                class="like-content bg-white m-l-20 m-r-20 m-t-16"
                style="
                    position: relative;
                    border-radius: 8px;
                    border: 1px solid #dbdbdb;
                    white-space: pre-wrap;
                    max-height: 280px;
                    overflow-y: scroll;
                "
            >
                <div class="title-box">
                    <span> {{ generateTitle() }} </span>
                </div>
                <div class="answer-box f-18 f-gowun-dodum">
                    <p>{{ generateAnswer() }}</p>
                </div>
            </div>
            <div v-else-if="options.type === 'photo'" class="img-box m-l-20 m-r-20 m-t-12">
                <img class="img-user" :src="options.photo" alt="user-photo" />
            </div>
            <div class="m-l-20 m-r-20 m-t-16">
                <span class="comment-badge">성사율 1.5배 ↑</span>
            </div>
            <div class="like-comment">
                <TextareaWithX
                    @input="onInputComment"
                    :textareaRows="rowCount"
                    :placeholder="placeholder"
                    v-model="inputContent"
                    :ref="'comment'"
                    class="comment m-16 m-t-6"
                />
            </div>
            <div class="send-btn m-l-20 m-r-20 m-t-8">
                <button class="send-like-btn f-14" @click="onClickLike('accepted')">수락하기</button>
            </div>
            <div class="send-btn m-l-20 m-r-20 m-t-8">
                <!-- <div class="manner-badge f-10 p-4">성사율 2배</div> -->
                <button class="send-like-btn manner-like f-14" @click="onClickLike('manner')">매너 수락하기</button>
            </div>
            <div class="manner-info f-13 m-t-16 text-center" @click="onClickInfo()">매너 수락이 무엇인가요?</div>
        </div>
    </div>
</template>

<script>
import profileService from '@/services/profile'
import chatService from '@/services/chat'

export default {
    name: 'ModalSendLike',
    props: ['options'],
    data: () => ({
        personalStories: null,
        inputContent: null,
        rowCount: 3,
        itemLiked: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
        placeholder() {
            return '(추천) 진정성 있는 코멘트를 남기면 매칭 확률이 1.5배 정도 올라가요.'
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        unlimitedRatePlan() {
            const ticketPlans = (this.userRatePlans && this.userRatePlans.onGoingTicketPlan) || []
            if (ticketPlans.length === 0) return null

            const plan = ticketPlans.find(o => o.code.includes('unlimited'))
            return plan || null
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        settings() {
            return this.$store.getters.settings || {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            console.log('unlimited111: ', this.unlimitedRatePlan)
            if (this.options.type === 'personalStory') {
                const personalStories = await profileService.personalStories()
                this.personalStories = personalStories
                this.itemLiked = this.options.userPersonalStories.id
            } else {
                this.itemLiked = this.options.photo
            }
        },
        generateTitle() {
            const title = this.personalStories.find(o => o.id === this.options.userPersonalStories.personal_story_id)
                .name
            return title
        },
        generateAnswer() {
            const answer = this.options.userPersonalStories.content
            return answer
        },
        onInputComment() {
            const rowCount = Math.ceil(this.inputContent.length / (this.$refs.comment.$refs.textarea.clientWidth / 15))
            if (rowCount <= 3) {
                this.rowCount = 3
            } else {
                this.rowCount = rowCount
            }
        },
        async onClickLike(status) {
            try {
                const isMichagam = this.options.target.photo_histories.is_michagam
                const noComment = !this.inputContent

                // 중복 모달 로직을 처리하는 함수
                const showModal = async (title, body, buttons) => {
                    return await this.$modal.basic({ title, body, buttons })
                }

                if (isMichagam) {
                    const michagamModalResult = await showModal(
                        null,
                        '(유의 사항) 미차감 만남의 경우 진정성 없는 태도로 만나거나, 만나서 미차감 사실을 직접 언급하거나 티내는 경우 상대방에게 결례가 될 수 있는 점 참고 부탁드립니다. <br/><br/>또한, 미차감 만남의 경우에도 약속 취소/변경 페널티는 동일하게 적용되는 점 참고해주세요.',
                        [
                            { label: 'CANCEL', class: 'btn-default' },
                            { label: '네, 알겠습니다.', class: 'btn-primary' },
                        ]
                    )
                    if (michagamModalResult !== 1) return
                }

                // 코멘트 없이 수락할지 묻는 모달
                if (noComment) {
                    const commentModalResult = await showModal(
                        null,
                        '코멘트 없이 수락하시겠습니까? <br />(내부 데이터 분석 결과 진정성 있게 코멘트를 입력하면 매칭 성사율이 약 30~50% 가량 증가합니다.)',
                        [
                            { label: '코멘트 작성하기', class: 'btn-default' },
                            { label: '그냥 수락하기', class: 'btn-primary' },
                        ]
                    )
                    if (commentModalResult !== 1) return
                }

                if (status === 'manner') {
                    const mannerModalResult = await showModal(
                        '매너 수락하기',
                        '상대방에게 매너 수락을 보냅니다. 매너 수락을 보내면 상대방은 만남권 사용 없이 만남을 할 수 있어 매칭 성사율이 2배 이상 높습니다. <br> 상대방이 수락하여 약속 진행 시 3만원의 별도 비용이 청구됩니다. 매너 수락하시겠습니까?',
                        [
                            { label: 'CANCEL', class: 'btn-default' },
                            { label: 'CONFIRM', class: 'btn-primary' },
                        ]
                    )
                    if (mannerModalResult !== 1) return
                }

                const acceptModalResult = await showModal(
                    `${
                        this.me.send_like_charge && this.validTickets.length === 0 && !this.unlimitedRatePlan
                            ? this.settings.point.send_like + ' 포인트를 소모하여 '
                            : ''
                    }"${this.options.target.profile.nickname}"님과의 만남을 수락합니다.`,
                    this.options.target.source_photo_histories && this.options.target.source_photo_histories.accepted
                        ? '• 상대방도 수락하여 약속 잡기가 시작됩니다.<br>• "약속" 메뉴에서 진행 상태를 확인할 수 있어요!'
                        : '• 상대방도 수락할 경우, 약속 잡기가 진행됩니다.<br>• 상대방이 수락한 경우에 약속을 잡아드리고, 수락하지 않는 경우 별도로 안내드리지 않습니다.',
                    [
                        { label: 'CANCEL', class: 'btn-default' },
                        { label: 'CONFIRM', class: 'btn-primary' },
                    ]
                )
                if (acceptModalResult === 1) {
                    const chat = this.$store.getters.chat
                    const payload = {
                        accept_status: status,
                        item_liked: this.itemLiked,
                        comment: this.inputContent,
                        type: this.options.type,
                    }
                    this.$loading(true)
                    await chatService.acceptProfile(chat.id, this.options.message.id, payload)
                    const message = chat.$$messages.find(message => message.id === this.options.message.id)
                    const content = this.$mustParse(message.content)

                    content.accept_status = status

                    message.content = JSON.stringify(content)

                    const respondedProfile = this.$store.getters.respondedProfile || []
                    this.$store.commit('updateRespond', [...respondedProfile, this.options.message.id])

                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'Send_Like_First',
                            option: {
                                gender: this.me.gender === 0 ? 'Male' : 'Female',
                                manner_like: status === 'manner',
                            },
                        },
                    })

                    this.$toast.success('수락 처리 되었습니다.')
                    this.$emit('close', 'sentLike')
                } else {
                    return
                }
            } catch (e) {
                this.$loading(false)
                if (e.data.key === 'not_enough_point') {
                    const idx = await this.$modal.basic({
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-default',
                            },
                            {
                                label: '충전하러 가기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx) {
                        this.$emit('close', 'moveInAppStorePage')
                    }
                } else {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.$loading(false)
                this.$stackRouter.pop()
            }
        },

        onClickInfo() {
            this.$modal.custom({
                component: 'ModalMannerLikeInfo',
                options: {},
            })
        },

        onClickClose() {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.modal-send-like {
    @include center;

    i {
        position: absolute;
        top: 24px;
        right: 20px;
    }

    .img-user {
        border-radius: 8px;
        width: 100%;
    }

    .title-box {
        padding-top: 20px;
        margin: 0 20px 0px 20px;
    }

    .answer-box {
        padding-bottom: 70px;
        margin: 12px 20px 0px 20px;
    }
    .comment-badge {
        padding: 4px;
        border-radius: 4px;
        background-color: #ff7070;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    }
    .send-btn {
        position: relative;
        display: flex;
        align-items: center;

        .send-like-btn {
            width: 100%;
            height: 48px;
            background-color: #ddd0da;
            color: #151360;
            border: none;
            border-radius: 31px;

            &.manner-like {
                background-color: #151360;
                color: white;
            }
        }

        .manner-badge {
            position: absolute;
            width: fit-content;
            background: white;
            border-radius: 4px;
            color: #151360;
            font-weight: 600;
            right: 70%;
        }
    }

    .manner-info {
        color: #5c5c77;
        text-decoration: underline;
    }
}
</style>
